<template>
  <div class="act-page page">
    <HeadBar class="headbar" title="活动中心"></HeadBar>
    <div class="act-page-content">
      <div
        class="act-page-content-item"
        v-for="(item, index) in actList"
        :key="index"
        :style="{
          background: item.bgImg ? `url(${item.bgImg}) no-repeat` : 'rgba(217, 217, 217, 1)',
          backgroundSize: '100% 100%'
        }"
        @click="handleClick(item)"
      >
        <p :style="{
          color: item.color
        }">{{ item.title }}</p>
        <span :style="{
          color: item.color
        }">{{ item.intro }}</span>
        <span :style="{
          color: item.color
        }">{{ item.time }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import useVip from '@/composables/useVip'
import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'SignInPage',
  setup(props) {
    const router = useRouter()
    const store = useStore()
    const { showVipQrcode } = useVip()
    const actList = computed(() => store.state.act.actList)

    onMounted(() => {
      store.dispatch('act/setActList')
      sendLog({
        event_type: 'click',
        event_name: 1732,
        event_data: {
          str1: 1
        }
      })
    })

    const handleClick = (val) => {
      console.log(val)
      if (val.detail) {
        if (val.detail === 'vip') {
          showVipQrcode()
          return
        }
        router.push({
        name: val.detail
      })
      }
    }

    return {
      actList,
      handleClick,
    }
  },
}
</script>

<style lang="stylus" scoped>
.act-page
  width 100%
  height 100%
  padding-top 35px
  position relative
  color #000
  font-weight 400
  &-content
    width 100%
    height 100%
    margin-top 35px
    display grid
    grid-template-columns repeat(3, 560px)
    justify-content space-between
    @media screen and (max-width 1200px) and (min-height 1200px)
      grid-template-columns repeat(2, 500px)
    &-item
      // width 560px
      margin-bottom 40px
      color #B4B4B4
      font-size 28px
      height 314px
      background #fff
      border-radius 22px
      padding-left 33px
      position relative
      background-position center
      p
        font-size 30px
        height 35px
        margin-top 193px
        margin-bottom 11px
        color #fff
        font-weight 600
        @media screen and (max-width 1200px) and (min-height 1200px)
          margin-top 173px
      span
        display block
        font-size 20px
        color #fff
      @media screen and (max-width 1200px) and (min-height 1200px)
        height 280px
        padding-left 30px
        p
          height 31px
          font-size 26px
        span
          font-size 18px
</style>